<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false"
    width="550px">
    <signature @Emit="(event) => { state.signatureData = event }" type="2"></signature>
    <!-- {{ state.signsData }} -->
    <signs class="mt-20" @Emit="(event) => { state.signsData = event }" type="2" v-if="state.CompanyIs"></signs>
    <div class="w100 dp-f mt-10 mb-10">
      <oabutton width="1000" height="32" style="border-radius: 2px" title="切换" CSStype="2"
        @buttonclick="unref(signtimeRef).changeshowType()" />
    </div>
    <signtime ref="signtimeRef" class="mb-10" @Emit="signtimeEmit" type="2"></signtime>
    <div class="dp-f">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="120px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="输入验证码：" prop='code' class="pt-r">
          <el-input v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入验证码" />
          <verificationCode class="pt-a vcode" @Emit="vCodeEmit" ref="verificationCodeRef"></verificationCode>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton width='1000' height='40' style="border-radius: 4px;" title="确定" CSStype=2 @buttonclick="batchSeal()"
          v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
  <el-dialog class="operation_dialog" v-model="dialogVisible2" title="温馨提示" :close-on-click-modal="false" width="550px">
    <div class="readbox mt-8">
      <div class="mb-15">· 请务必确保已知晓所选文件的内容;</div>
      <div class="mb-15">· 仅指定签署位置的文件可进行批量签署;</div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f jc-sb pl-10">
        <el-checkbox v-model="state.checked" class="mr-20">我已知晓，不再提示</el-checkbox>
        <div class="dp-f">
          <oabutton  class="mr-12" width='60' height='32' style="border-radius: 4px;" title="确定" CSStype=2 @buttonclick="checkedClick"/>
          <oabutton width='60' height='32' style="border-radius: 4px;" title="确定" CSStype=1 @buttonclick="dialogVisible2=false"/>
        </div>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, computed,onMounted } from 'vue'
import { httpToken } from "@/utils/request";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { router_push_name } from "@/utils/server/router.js"
import signature from "@/views/operation/signContract/components/signDetails/collapseInfo/signature.vue"; //个人签名
import signs from "./signs.vue"; //选择签章
import signtime from "@/views/operation/signContract/components/signDetails/collapseInfo/signtime.vue"; //签署日期
import qs from "qs";
import verificationCode from '@/components/button/verificationCode.vue'//验证码组件
const verificationCodeRef = ref()//验证码组件ref
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const dialogVisible2= ref(false)
// willExpression 意愿表达式(0: 不选择 1: 短信验证码 2: ukey证书 3: 密码验证 4: 人脸识别验证 )
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const signtimeRef = ref()
const state = reactive({
  isRead:true,//是否已阅读协议
  checked:false,//是否已知晓
  signatureData: null,//签名数据
  signsData: null,//签章数据
  signtimeData: {

  },//签署时间相关数据
  data: [],//批量签署选中数据
  newVerifyId: '',//当前缓存证书msg
  CompanyIs:false,
})
onMounted(() => {
  let userInfo = JSON.parse(window.sessionStorage.user).user_info
  if (userInfo.sysCompany) {
    state.CompanyIs=true
  }
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  code: '',//验证码
})
const rules = reactive({//from 表单校验
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
})
const signtimeEmit = (el) => {
  state.signtimeData = {
    showType: el.showType,
    NowDate: el.NowDate,
    NowTime: el.NowTime,
  }
}
const vCodeEmit = () => {
  if (state.CompanyIs) {
    httpToken({
      method: "post",
      url: '/initiate-contract/contract/isOKSealPositionAll',
      data: qs.stringify({
        contract_id: state.data.map(item => {
          return item.id
        }).join(','),//合同id集合(字符串数组)
        sealId:JSON.stringify(state.signsData)
      }),
    }).then((res) => {
      if(res.data){
        sendsignatureCode()
      }
    })
  }else{
    sendsignatureCode()
  }
}
const sendsignatureCode=()=>{
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/signatureCode',
    data: qs.stringify({
      file_id: null,
      contract_id: state.data[0].id,
      signature_status: 1
    })
  }).then(res => {
    unref(verificationCodeRef).send()
    state.newVerifyId = res.msg
  })
}

const batchSeal = async () => {
  if (!state.signatureData) {
    handleMessage('请选中签名数据')
    return
  }
  if (state.CompanyIs) {
    if (!state.signsData) {
      handleMessage('请选中签章数据')
      return
    }
  }
  if (state.newVerifyId == '') {
    handleMessage('请先发送验证码')
    return
  }
  console.log('state.signtimeData', state.signtimeData)
  let namestr = state.signtimeData.NowDate
  if (state.signtimeData.showType == 2) {
    namestr += ' '
    namestr += state.signtimeData.NowTime
  }
  dialogloading.value = true
  console.log('namestr', namestr)
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      let json = {
        contracts: JSON.stringify(state.data.map(item => {
          return item.id
        })),//合同id集合(字符串数组)
        code: ruleForm.code,//验证码
        signLocation: 'PC',//类型
        newVerifyId: state.newVerifyId,//当前缓存证书msg
        personalSignatureId: state.signatureData.id,//签名
        // signNameType:state.signatureData.type,
        name: namestr
      }
      if (state.CompanyIs) {
        json.sealId=JSON.stringify(state.signsData)
      }
      httpToken({
        method: "post",
        url: '/initiate-contract/contract/PDFSignatureBatch',
        data: qs.stringify(json)
      }).then(res => {
        dialogloading.value = false
        dialogVisible.value = false
        handleMessage('签署成功', 'success')
        emit('Emit')
      }).catch(() => {
        dialogloading.value = false
      })
    } else {
      dialogloading.value = false
    }
  })
}
const checkedClick=()=>{
  if(state.checked){
    // state.isRead=true
    dialogVisible2.value=false
    batchShow()
  }else{
    handleMessage('请知晓并确认条款')
  }
}
const show = ((data) => {
  state.data = data
  if(state.isRead){
    batchShow()
  }else{
    dialogVisible2.value = true
  }
})
const batchShow = (() => {
  ruleForm.code = ''//验证码
  dialogloading.value = false
  dialogtitle.value = '签署意愿确认'
  dialogVisible.value = true
})
defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
.readbox{
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #142239;
}
::v-deep .el-checkbox__label{
  color: #808896;
}
::v-deep .input_box {
  height: 32px !important;
  line-height: 32px !important;

  .el-input {
    height: 32px !important;
    line-height: 32px !important;
  }
}

.vcode {
  z-index: 10;
  right: 18px;
}
</style>