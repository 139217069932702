<template>
  <div class="signatures">
    <div class="dp-f mb-10">
      <!-- <div class="input_box w-200">
        <el-input class="input search_input" placeholder="搜索签名名称" v-model="state.searchKey"
          :maxlength="config.inputMaxlength" show-word-limit>
          <template #append>
            <img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="selectSignatures(state.searchKey)">
          </template>
        </el-input>
      </div> -->
      <!-- <oabutton width='80' height='32' title="搜索" CSStype=2 style="border-radius:4px;" class=""
        @buttonclick="selectSignatures(state.searchKey)"></oabutton> -->
      <oabutton width='120' height='32' style="border-radius: 2px;" :imgname="(ThemeTypeIndex == 1||ThemeTypeIndex == 2||ThemeTypeIndex == 3) ? 'add2.png' : 'add.png'" title="新建签名" CSStype=2
        @buttonclick="clickUserCheck(() => { unref(setSignRef).show() })" />
    </div>
    <div class="showbox dp-f">
      <div v-for="(el, ind) in state.signatures" :key="ind" class="itembox cu-p"
        :class="el.selectedState ? 'itemboxac' : ''">
        <!-- <el-dropdown @command="handleCommand">
          <img class="pt-a moreIcon" src="@/assets/img/general/more.png">
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>编辑</el-dropdown-item>
              <el-dropdown-item :command="{ type: 'del', data: el }">删除</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        <div class="imgbox dp-fc" ref="dragitemRef" @click="props.type==2?clickUserCheck(() => { debounceClick(el) }):''" @mousedown="clickUserCheck(() => {mouseFun($event,el)})" id="drag">
          <img :src="el.url">
          <img class="pt-a selectedIcon" src="@/assets/img/general/selectac.png" v-if="el.selectedState">
        </div>
        <div class="name">{{ el.name }}</div>
      </div>
    </div>
  </div>
  <setSign ref="setSignRef" @Emit="getSignatureData()"></setSign>
</template>
<script setup>
import { ref, unref, computed, watch, reactive, defineEmits,defineProps,nextTick } from "vue";
import { debounce } from "@/utils/util.js";
import { httpToken } from "@/utils/request";
import { useStore } from "vuex";
import { clickUserCheck } from "@/utils/base/realname.js"
// import {dragFunc} from "@/utils/server/drag.js"
import qs from "qs";
import setSign from "@/views/user/components/userModule/sign/setSign.vue";
const props = defineProps({
  type: {
    type: Number,
    default() {
      return 1//1默认 2批量签署
    },
  },
});
const store = useStore();
const ThemeTypeIndex = computed(() => {
  return store.state.theme.typeIndex
})//当前选中主题下标
const mouseFun=(e,el)=>{
  // getnowpositionData
  // console.log('鼠标事件',e,el)
  //1 签名 width: 134,height: 34,
  store.state.Mouseleft=(e.clientX - 67)
  store.state.Mousetop=(e.clientY - 17)
  store.state.MouseData=el
  emit('nowpositionEmit',el,1)
  // store.state.Mouseis=true
}
const emit = defineEmits(['Emit','nowpositionEmit'])
const setSignRef = ref()
const dragitemRef = ref()
const state = reactive({
  searchKey: '',//搜索绑定值
  signatures: [],// 个人签名签署数据
  signaturesAll: []
});
// 搜索印章
const selectSignatures = (value) => {
  state.signatures = state.signaturesAll.filter((currentValue, index, arr) => {
    return currentValue.name.includes(value)
  })
}
const handleCommand = (el) => {
  if (el.type == 'del') {
    delSign(el.data)
  }
}
// 删除印章
const delSign = (item) => {
  httpToken({
    method: "post",
    url: '/admin/personalsignature/del',
    data: qs.stringify({
      ids: item.signatureId
    })
  }).then((res) => {
    getSignatureData()
  })
}
const getMySign = () => {
  httpToken({
    method: "post",
    url: '/admin/personalsignature/all',
  }).then((res) => {
    state.signList = res.data
    store.commit('SET_LENGTH', res.data.length)
  })
}
getMySign()
// 获取签名数据
const getSignatureData = () => {
  httpToken({
    method: "post",
    url: '/admin/personalsignature/all',
  }).then((res) => {
    res.data.forEach(item => {
      item.id = item.fileId,
      item.url = item.fileUrl
      item.name = item.signatureName
      item.selectedState = false
    });
    state.signatures = res.data
    state.signaturesAll = res.data
    if(props.type==2){
      if(state.signatures.length>0){
        emit('Emit', JSON.parse(JSON.stringify(state.signatures[0])))
        state.signatures[0].selectedState=true
      }
    }
  })
}
getSignatureData()
const debounceClick = debounce((el) => { 
  emit('Emit', JSON.parse(JSON.stringify(el)))
  if(props.type==2){
    state.signatures.forEach(item=>{
      item.selectedState=false
    })
  }
  el.selectedState = true 
}, 200)
</script>
<style lang="scss" scoped>
.signatures {
  .showbox {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    border: 1px solid #E6E6E6;

    :nth-child(3n).itembox {
      margin-right: 0;
    }

    .itembox {
      width: 32%;
      margin-right: 2%;
      position: relative;

      ::v-deep .el-dropdown {
        position: absolute;
        top: 4px;
        right: 36px;
        z-index: 99;
      }

      .imgbox {
        width: 100%;
        height: 100px;
        border: 1px solid #E6E6E6;
        position: relative;

        .selectedIcon {
          right: 0;
          bottom: 0;
        }

        .moreIcon {
          top: 4px;
          right: 4px;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
      }
    }

    .itemboxac {
      .imgbox {
        border: 1px solid #EAC473;
      }
    }
  }

  ::v-deep .button {
    img {
      margin-right: 4px;
    }
  }
}

.input_box {
  height: 32px;
  line-height: 32px;
  .el-input {
    height: 32px !important;
    line-height: 32px !important;
  }
}
</style>