<template>
  <!-- @click="debounceClick(NowDate,NowTime)" -->
  <div class="title mb-10 dp-f jc-sb">
    <div>签署日期</div>
    <oabutton width="64" height="32" style="border-radius: 2px" title="切换" CSStype="2" class="ml-14"
      @buttonclick="changeshowType()" />
  </div>
  <div class="signtime cu-p dp-fc" @mousedown="mouseFun($event)">
    {{ NowDate }}<span class="ml-6" v-if="state.showType == 2">{{ NowTime }}</span>
  </div>
  
</template>
<script setup>
import { getNowALLTime } from "@/hooks/getTime";
import { ref, unref, computed, watch, reactive, defineEmits, defineProps, onMounted } from "vue";
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
import { debounce } from "@/utils/util.js";
import { useStore } from "vuex";
const props = defineProps({
  type: {
    type: Number,
    default() {
      return 1//1默认 2批量签署
    },
  },
});
const store = useStore();
const { NowTime, NowDate } = getNowALLTime();
const emit = defineEmits(['Emit', 'nowpositionEmit'])
  //2 日期 width: 176,height: 38,
const mouseFun = (e) => {
  // getnowpositionData
  // console.log('鼠标事件',e,el)
  store.state.Mouseleft = (e.clientX - 88)
  store.state.Mousetop = (e.clientY - 19)
  store.state.MouseData = {
    showType: state.showType,
    NowDate: NowDate.value,
    NowTime: NowTime.value,
  }
  console.log('store.state.MouseData',store.state.MouseData)
  emit('nowpositionEmit', {
    showType: state.showType,
    NowDate: NowDate.value,
    NowTime: NowTime.value,
  }, 2)
  // store.state.Mouseis=true
}
let state = reactive({
  showType: 1,//展示的类型 1年月日 2年月日 时分秒
  searchKey: '',//搜索绑定值
});
const debounceClick = debounce((NowDate, NowTime) => {
  emit('Emit', {
    showType: state.showType,
    NowDate: NowDate.value,
    NowTime: NowTime.value,
  })
}, 200)
const changeshowType = () => {
  state.showType == 1 ? state.showType = 2 : state.showType = 1
  if (props.type == 2) {
    emit('Emit', {
      showType: state.showType,
      NowDate: NowDate.value,
      NowTime: NowTime.value,
    })
  }
}
onMounted(() => {
  if (props.type == 2) {
    emit('Emit', {
      showType: state.showType,
      NowDate: NowDate.value,
      NowTime: NowTime.value,
    })
  }
})
defineExpose({
  changeshowType
})
</script>
<style lang="scss" scoped>
.signtime {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>