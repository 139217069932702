// 根据毫秒值获取偏差时间
export function formatDuring(mss) {
  let days = parseInt(mss / (1000 * 60 * 60 * 24));
  let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = (mss % (1000 * 60)) / 1000;
  let str=''
  // days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";
  if(days>0){
    str+=days + " 天 "
  }
  if(hours>0){
    str+=hours + " 小时 "
  }
  if(minutes>0){
    str+=minutes + " 分钟 "
  }
  if(seconds>0){
    str+=seconds + " 秒 "
  }
  return str
}

// 根据new DATE返回当前年月日   is为true返回时分秒
export function getstingtime(myDate, is = false,Yearunit="-",Monthunit="-",Dayunit="",timeunit=':') {
  let detail_time =
    myDate.getFullYear() +
    Yearunit +
    timep(myDate.getMonth() + 1) +
    Monthunit +
    timep(myDate.getDate())+Dayunit
  if (is) {
    detail_time += " " +
      timep(myDate.getHours()) +
      timeunit +
      timep(myDate.getMinutes()) +
      timeunit +
      timep(myDate.getSeconds());
  }
  return detail_time;
}
// 根据new DATE 获取中文的星期名
export function get_weekc(myDate) {
  let zweek = myDate.getDay();
  let weekc = ''
  switch (zweek) {
    case 1:
      weekc = '星期一'
      break;
    case 2:
      weekc = '星期二'
      break;
    case 3:
      weekc = '星期三'
      break;
    case 4:
      weekc = '星期四'
      break;
    case 5:
      weekc = '星期五'
      break;
    case 6:
      weekc = '星期六'
      break;
    default:
      weekc = '星期天'
      break;
  }
  return weekc
}
// 根据new DATE 获取时分秒
export function get_Hourmstime(myDate, type,timeunit=":") {
  let detail_time = ''
  if (type == 1) {
    detail_time =
      timep(myDate.getHours()) +
      timeunit +
      timep(myDate.getMinutes()) +
      timeunit +
      timep(myDate.getSeconds());
  } else {
    detail_time =
      timep(myDate.getHours()) +
      "时" +
      timep(myDate.getMinutes()) +
      "分" +
      timep(myDate.getSeconds()) + '秒';
  }
  return detail_time
}
function timep(s) {
  return s < 10 ? "0" + s : s;
}