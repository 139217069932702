import {ref,onUnmounted} from 'vue'
import {get_weekc,getstingtime,get_Hourmstime} from "@/utils/server/getTime";
// 获取 当前日期 详情与时分秒
export function getAllTime(myDate){
  const HookTime = ref('')//时分秒
  const HookDate = ref('')//年月日
  const HookDay = ref('')//日
  const HookWeek = ref('')//星期
  HookTime.value=get_Hourmstime(myDate,1)
  HookDate.value = getstingtime(myDate)
  HookWeek.value=get_weekc(myDate);
  HookDay.value=myDate.getDate()
  return {HookTime,HookDate,HookWeek,HookDay}
}
// 获取当前时间 时分秒
export function getNowTime(){
  const NowTime = ref('')//时分秒
  NowTime.value=get_Hourmstime_c()
  let setIntervalobj=setInterval(()=>{
    NowTime.value=get_Hourmstime_c()
  },1000)
  onUnmounted(()=>{
    window.clearInterval(setIntervalobj);
  })
  return {NowTime}
}
function get_Hourmstime_c(){
  let myDate = new Date();
  return get_Hourmstime(myDate,1)
}
// 获取 当前日期 详情与时分秒
export function getNowALLTime(Yearunit,Monthunit,Dayunit){
  const NowTime = ref('')//时分秒
  const NowDate = ref('')//年月日
  const NowWeek = ref('')//星期
  let myDate = new Date();
  NowTime.value=get_Hourmstime(myDate,1)
  NowDate.value = getstingtime(myDate,undefined,Yearunit,Monthunit,Dayunit)
  NowWeek.value=get_weekc(myDate);
  let setIntervalobj=setInterval(()=>{
    let myDate = new Date();
    if(Verification12time(myDate)){
      NowTime.value=get_Hourmstime(myDate,1)
      NowDate.value = getstingtime(myDate,undefined,Yearunit,Monthunit,Dayunit)
      NowWeek.value=get_weekc(myDate);
    }else{
      NowTime.value=get_Hourmstime(myDate,1)
    }
  },1000)
  onUnmounted(()=>{
    window.clearInterval(setIntervalobj);
  })
  return {NowTime,NowDate,NowWeek}
}
// 判断是否为12点
function Verification12time(myDate){
  if (myDate.getHours() == 0 && myDate.getMinutes() == 0) {return true}
  else { return false}
}
