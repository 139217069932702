<template>
  <div>
    <div class="verificationCode cu-p" :class="['verificationCode' + CSStype]" v-show="!state.wait" @click="emit('Emit')">
      获取验证码
    </div>
    <div v-show="state.wait" class="time" :class="['time' + CSStype]">
      {{ state.time }}s
    </div>
  </div>
</template>
<script setup>
// type 1 登录 2 注册 3 重置密码 4 修改密码 5 实名认证 6 设置签署密码 7 签署密码校验 8 用户注销 9 新任务通知 10修改手机号
import { reactive, ref, defineEmits, onUnmounted } from "vue";
const emit = defineEmits(['Emit'])
const props = defineProps({
  // 定时器执行时间
  setInterval_time: {
    type: Number,
    default: () => { return 60 }
  },
  // css 类型
  CSStype: {
    type: Number,
    default: () => { return 0 }
  },
});
const state = reactive({
  setIntervalObj: {},//定时器对象
  wait: false,//是否等待
  time: 0,//当前剩余时间
});
// 短信发送方法
const send = (() => {
  state.time = props.setInterval_time
  state.wait = true
  use_setInterval()
})
// 执行循环定时器
const use_setInterval = (() => {
  state.setIntervalObj = setInterval(() => {
    state.time--
    if (state.time == 0) {
      state.wait = false
      clearInterval()
    }
  }, 1000)
})
// 清除定时器
const clearInterval = (() => {
  window.clearInterval(state.setIntervalObj);
})
onUnmounted(() => {
  clearInterval()
})
defineExpose({
  send
});
</script>
<style lang="scss" scoped >
@import '@/styles/color/value.scss';

.verificationCode {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #276FF9;
}

.verificationCode1 {
  color: $active-theme;
}

.verificationCode2 {
  flex-shrink: 0;
  width: 132px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid $active-theme;
  color: $active-theme;
}

.time {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.verificationCode,
.time {
  min-width: 100px;
  text-align: center;
}

.time2 {
  flex-shrink: 0;
  width: 132px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #333;
}</style>